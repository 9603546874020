import React from 'react';
import { Link } from 'gatsby';

import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import {
    CourseContainer,
    PriceContainer,
    CourseHighlightList
} from './components/CoursesList';

const Cooking = () => (
    <CourseContainer key="one-day-cooking">
        <div className="columns">
            <div className="column is-6">
                <h2 className="has-text-primary is-size-4">
                    ONE DAY COOKING CLASS
                </h2>
                <p className="has-text-primary is-size-5">
                    with Chef Karolina ||{' '}
                    <span className="is-size-5" style={{ color: 'green' }}>
                        Various Dates Available
                    </span>
                </p>
                <p>At Villa Sole</p>
            </div>
            <div className="column is-6">
                <PriceContainer>
                    <div>
                        <span style={{ display: 'block' }}>1 day</span>
                        <span style={{ display: 'block' }}>All inclusive</span>
                    </div>
                    <span style={{ marginLeft: '10px' }} className="has-text-weight-bold is-size-3">
                        Price on request
                    </span>
                </PriceContainer>
            </div>
        </div>
        <div className="columns">
            <div className="column is-3">
                <PreviewCompatibleImage
                    className=""
                    imageInfo={{
                        image: '../../img/karolina-cooking.jpg',
                        alt: `featured image thumbnail for post one day cooking`
                    }}
                />
            </div>
            <div className="column is-9">
                <p>
                    This one day cooking class is perfect for a group of
                    friends, family or couples looking to experience authentic
                    italian food in a beautiful, relaxed and fun setting. If you
                    love Italian food and want to discover new and delicious
                    recipes in stunning Tuscan surroundings - this is the
                    cooking class for you! The One Day Cooking Classes in Villa
                    Selva begins with selecting the freshest of ingredients from
                    the beautiful garden followed by a three hour cooking
                    workshop with our amazing English-speaking chef Karolina who
                    will walk you through making a classic Italian dinner with a
                    primo (pasta dish), a secondo (main course), a contorno
                    (side dish) and a delectable dolce, (sweet course). This is
                    followed by a wonderful meal and selection of local wines
                    tasted during the lesson. If there is something in
                    particular you would like to cook, you are welcome to
                    specify with the chef beforehand. Classes are taught in a
                    fun and relaxed atmosphere and you'll each have a go at
                    making your own pasta and get a real feel for authentic
                    italian cooking experience. Please know that we are taking
                    extra precautions to keep our customers safe, we only offer
                    private classes to guarantee an exclusive, unique hands-on
                    lesson.
                </p>
                <CourseHighlightList type="disc">
                    <li>
                        English-speaking private chef (Karolina also speaks
                        Polish and Italian)
                    </li>
                    <li>Prosecco welcome reception</li>
                    <li>
                        Villa Sole's Garden tour (to pick your own herbs and
                        veg)
                    </li>
                    <li>All ingredients and equipment</li>
                    <li>
                        5 course meal with wine at the end of the course (LUNCH
                        or DINER)
                    </li>
                    <li>Follow Your Senses in Tuscany Apron</li>
                    <li>All Recipes emailed as PDFs</li>
                </CourseHighlightList>
                <Link className="btn" to="/cooking">
                    Find Out More
                </Link>
            </div>
        </div>
    </CourseContainer>
);

export default Cooking;
