import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PreviewCompatibleImage from '../../../components/PreviewCompatibleImage';
import mediaSizes from '../../../components/MediaSizes';

export const CourseContainer = styled.div`
    background-color: #fafafa;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
    transition: 0.4s;

    &:hover {
        transform: scale(1.02, 1.02);
        box-shadow: rgba(136, 136, 136, 0.2) 0px 5px 10px;
    }
`;

export const CourseHighlightList = styled.ol`
    list-style-type: disc;
`;

export const PriceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: ${mediaSizes.tablet}px) {
        justify-content: flex-start;
    }
`;

export const SoldOut = styled.div`
    color: red;
    display: block;
    text-align: left;

    span {
        display: block;
    }

    @media (min-width: ${mediaSizes.tablet}px) {
        float: right;
        text-align: right;
    }

`;

export const Price = (numberOfDays, cost, soldout) => (
    <React.Fragment>
        <PriceContainer>
            <div>
                <span style={{ display: 'block' }}>{numberOfDays} days</span>
                <span style={{ display: 'block' }}>All inclusive</span>
            </div>
            <span style={{ marginLeft: '10px' }} className="is-size-2">
                {cost}
            </span>
        </PriceContainer>
        {soldout && (
            <SoldOut>
                <span className="is-size-4">
                    Sold Out!
                </span>
                <span>sign up to waiting list</span>
            </SoldOut>
        )}
    </React.Fragment>
);

const CoursesList = props => {
    const { data } = props;
    const { edges: courses } = data.allMarkdownRemark;
    return (
        <React.Fragment>
            {courses &&
                courses.map(({ node: course }) => (
                    <CourseContainer key={course.id}>
                        <div className="columns">
                            <div className="column is-6">
                                <h2 className="has-text-primary is-size-4">
                                    {course.frontmatter.title}
                                </h2>
                                <p className="has-text-primary is-size-5">
                                    with {course.frontmatter.instructor} ||{' '}
                                    <span
                                        className="is-size-5"
                                        style={{ color: 'green' }}
                                    >
                                        {course.frontmatter.startDate} -{' '}
                                        {course.frontmatter.endDate}
                                    </span>
                                </p>
                                <p>At {course.frontmatter.location}</p>
                            </div>
                            {course.frontmatter.days && course.frontmatter.cost && (
                            <div className="column is-6">
                                {Price(
                                    course.frontmatter.days.length,
                                    course.frontmatter.cost,
                                    course.frontmatter.soldout
                                )}
                            </div>
                            )}
                        </div>
                        <div className="columns">
                            {course.frontmatter.featuredimage && (
                                <div className="column is-3">
                                    <PreviewCompatibleImage
                                        className=""
                                        imageInfo={{
                                            image:
                                                course.frontmatter
                                                    .featuredimage,
                                            alt: `featured image thumbnail for post ${course.frontmatter.title}`
                                        }}
                                    />
                                </div>
                            )}
                            <div className="column is-9">
                                <p>{course.frontmatter.description}</p>
                                {course.frontmatter.courseHighlights && (
                                    <CourseHighlightList type="disc">
                                        {course.frontmatter.courseHighlights.map(
                                            highlight => (
                                                <li>{highlight.highlight}</li>
                                            )
                                        )}
                                    </CourseHighlightList>
                                )}
                                <Link className="btn" to={course.fields.slug}>
                                    Find Out More
                                </Link>
                            </div>
                        </div>
                    </CourseContainer>
                ))}
        </React.Fragment>
    );
};

CoursesList.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array
        })
    })
};

export default () => (
    <StaticQuery
        query={graphql`
            query CoursesListQuery {
                allMarkdownRemark(
                    sort: { order: ASC, fields: [frontmatter___startDate] }
                    filter: { frontmatter: { templateKey: { eq: "course" } } }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                startDate(formatString: "DD MMMM")
                                endDate(formatString: "DD MMMM, YYYY")
                                location
                                description
                                featuredcourse
                                soldout
                                cost
                                days {
                                    day
                                    dayDescription
                                }
                                instructor
                                courseHighlights {
                                    highlight
                                }
                                date(formatString: "MMMM DD, YYYY")
                                featuredimage {
                                    childImageSharp {
                                        fluid(maxWidth: 600, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data, count) => <CoursesList data={data} count={count} />}
    />
);
